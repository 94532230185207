import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';

const PodcastsVideosPage = () => (
    <InnerPage title="Podcasts und Videos">
        <IntroHolder
            title="Podcasts und Videos"
            breadcrumbs={{
                'current-item': 'Podcasts und Videos',
            }}
        ></IntroHolder>

        <TwoColumns>
            <Content>
                <h2 className="h4">Podcast</h2>
                <p>
                    „Einblicke in…“ … diverse Themen, Gesprächsrunden, oder
                    Entwicklungen in der Bahá’í-Gemeinde
                </p>
                <br />
                <div>
                    <iframe
                        width="100%"
                        height="480"
                        scrolling="no"
                        frameborder="no"
                        allow="autoplay"
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/848390255&color=%234a7eb5&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
                    ></iframe>
                </div>
                <h2 className="h4">Videos</h2>
                <p>
                    Folgen Sie Erkenntnissen und Erfahrungen aus dem Spektrum
                    der Bemühungen der Bahá’i-Gemeinde gemeinsam mit weiteren
                    Akteuren zu gesellschaftlichem Zusammenhalt beizutragen:
                </p>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/w-tuoO-uoAk"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/4QKIEfNWk3U"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/bYc_lHjGEzk"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/LjTAhioqbwk"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/iS-PQk5iYPU"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/VBc-wB7bQJY"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/kn4rhV7-stE"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/iOnhRmYrGyc"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/B2ZmmXoAOjQ"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/Fl5ytOPFuZI"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/tdFY_lVXzeQ"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/0BGjsX5LvCg"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/8jAT2g9pB2k"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <br />
                <div className="video-wrapper">
                    <iframe
                        src="https://www.youtube.com/embed/T6mpq_3viwY"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default PodcastsVideosPage;
