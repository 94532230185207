import React from 'react';
import { PrimaryInnerPage } from '@bahaide/shared/components/InnerPage';
import { StaticQuery, graphql } from 'gatsby';

function InnerPage({ children, title, description, image, pageType }) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            defaultTitle: title
                            titleTemplate
                            defaultDescription: description
                            siteUrl
                            defaultImage: image
                            twitterUsername
                        }
                    }
                }
            `}
            render={data => (
                <PrimaryInnerPage
                    site="aktuelles"
                    metadata={data.site.siteMetadata}
                    environment={process.env.GATSBY_ACTIVE_ENV}
                    children={children}
                    title={title}
                    image={image}
                    description={description}
                    pageType={pageType}
                />
            )}
        />
    );
}

export default InnerPage;
